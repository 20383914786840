import React from 'react';
import {
  Button,
  Container,
  Flex,
  Heading,
  Text,
} from '@talent-garden/react-components';
import { Image, Meta } from '../components';
import styles from '../styles/Companies.module.css';

const Custom404 = () => {
  return (
    <Container className={styles.container}>
      <Meta title="Page not found | TAG Connect" noFollow />
      <Flex direction='column' className={styles.main} horizontalAlign='center'>
        <Flex
          direction='column'
          className={styles.content}
          horizontalAlign='center'
          rowGap={40}
        >
          <Flex direction='column' horizontalAlign='center' rowGap={16}>
            <Image
              src='/images/error_image.png'
              alt='Error image'
              width={120}
              height={120}
            />
            <Heading as='h2' variant textAlign='center'>
              Page not found
            </Heading>
            <Text textAlign='center'>The page you are trying to visit does not exist.</Text>
            <Button
              as='button'
              capitalize
              dimension='regular'
              kind='primary'
              target='_blank'
              onClick={() => {
                sessionStorage.clear();
                localStorage.clear();
                window.location.replace('/api/auth/logout');
              }}
            >
              BACK
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Custom404;
